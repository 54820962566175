import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Introduction from "./components/introduction";
import "./App.css";
import Footer from "./components/footer";
import Portfolio from "./components/portfolio";
import LandingPage from "./components/landingPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import UhOh from "./UhOh";
import { inject } from "@vercel/analytics";

inject();
export default function App() {
  const isMobileWidth = useMediaQuery("(max-width:800px)");

  return isMobileWidth ? (
    <UhOh />
  ) : (
    <Container
      maxWidth={true}
      style={{
        padding: 0,
        margin: 0,
        position: "relative",
        zIndex: 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        height: "1400px",
        width: "100vw",
        marginX: "auto",
        float: "left",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ position: "relative", zIndex: 1, height: "auto" }}
      >
        <Grid
          item
          id="g-snows"
          xs={12}
          sx={{
            backgroundColor: "rgb(83, 148, 189)",
            marginX: "auto",
            position: "static",
            zIndex: -1,
            pt: 25,
            pb: 65,
          }}
        >
          <Box sx={{ pt: "25vh" }}>
            <LandingPage />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: "static",
            zIndex: 4,
            marginX: "auto",
            transform: "translateY(-1rem)",
            pb: 2,
          }}
        >
          <Introduction />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: "static",
            zIndex: 4,
            transform: "translateY(-13rem)",
            marginX: "auto",
          }}
        >
          <Portfolio />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: "-25rem", height: "auto", marginX: "auto" }}
        >
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
}
