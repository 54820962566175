import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Parallax } from "react-scroll-parallax";
import { Element, Link, animateScroll as scroll } from "react-scroll";
import CodecademyImage from "../assets/code-academy-e1590056964242-modified.png";
import UdemyImage from "../assets/logos-removebg-preview.png";
import GoogleImage from "../assets/google-pngrepo-com.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import ReactModal from "./certification-components/reactModal";
import JavascriptModal from "./certification-components/javascriptModal";
import CSSModal from "./certification-components/cssModal";
import HTMLModal from "./certification-components/htmlModal";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import SaveIcon from "@mui/icons-material/Save";
import HandymanIcon from "@mui/icons-material/Handyman";
import GitHubIcon from "@mui/icons-material/GitHub";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";
import WebResponsiveExample from "../../portfolio/components/webResponsiveExample";
import MobileResponsiveExample from "../../portfolio/components/mobileResponsiveExample";

import Carousel from "nuka-carousel";

import gcImage from "../../portfolio/images/gc-logo.png";
import cottageImage from "../../portfolio/images/cottageLogo.png";
import privateLogo from "../../portfolio/images/privatecompany-logo.png";
import cityAsASchoolLogo from "../../portfolio/images/city-as-a-school-logo.png";
import chippLogo from "../../portfolio/images/chipp-logo.png";
const actions = [
  {
    icon: (
      <span>
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "about",
  },
  {
    icon: (
      <span>
        <HandymanIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "skills",
  },
  {
    icon: (
      <span>
        <GitHubIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "github",
  },
  {
    icon: (
      <span>
        <SchoolIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "experience",
  },
  {
    icon: (
      <span>
        <WorkIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <span>
        <RecommendIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <span>
        <MailIcon sx={{ color: "#28282B" }} />
      </span>
    ),
    name: "contact",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "740px",
  height: "auto",
  bgcolor: "rgba(245, 245, 245, 0.98)",
  border: "2px solid #000",
  py: 4,
  px: 4,
};

const scrollableTypographyStyle = {
  maxHeight: "50vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "10px",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.7)",
    },
  },
};

const CertificationComponent = () => {
  const isMobileWidth = useMediaQuery("(max-width:600px)");
  const [progress, setProgress] = useState(0);

  
  const [openCodecademy, setOpenCC] = React.useState(false);
  
  const handleCloseCC = () => {
    setOpenCC(false);
  };

  const [openUdemy, setOpenUD] = React.useState(false);
  
  const handleCloseUD = () => {
    setOpenUD(false);
  };
  
  const [openGoogle, setOpenG] = React.useState(false);
  
  const handleCloseG = () => {
    setOpenG(false);
  };

  return (
    <>
      <Modal
        open={openCodecademy}
        onClose={handleCloseCC}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 600, border: null, display: "flex" }}>
          <Box
            sx={{
              width: "450px",
              display: "flex",
              flexDirection: "column",
              pr: 3,
            }}
          >
            <img
              src={CodecademyImage}
              className="codecademy-photo-modal"
              alt="Codecademy logo"
            />
            <Typography
              sx={{
                color: "#28282B",
                pt: 4,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                wordSpacing: "-6px",
              }}
            >
              <span id="pro">PRO</span> Career Path:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              Front-End Engineer
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "35auto",
              }}
            >
              Course Duration:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              4-8 Months
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
              }}
            >
              Comprehensive course covering HTML, CSS, JavaScript, Web Apps,
              Git, APIs, React, Redux, UX design, Complex Data Structures,
              Algorithms, and more.
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1.3rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                fontWeight: "bold",
              }}
            >
              85% Completed
            </Typography>
            <LinearProgress
              variant="determinate"
              thickness={4}
              value={85}
              sx={{
                width: "85%",
                mt: 1,
                ml: 2.5,
                mb: 1,
                borderRadius: "8px",
                p: "4px",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "200px",
              borderLeft: "2px solid black",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                paddingTop: "3rem",
                color: "#FFAD05",
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                display: "flex",
                pl: 4,
              }}
            >
              Completed Certificates:
            </Typography>
            <JavascriptModal />
            <CSSModal />
            <HTMLModal />
          </Box>
        </Box>
      </Modal>

      {/*uDemy modal*/}

      <Modal
        open={openUdemy}
        onClose={handleCloseUD}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 600,
            border: null,
            display: "flex",
            marginX: "auto",
          }}
        >
          <Box sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
            <img
              src={UdemyImage}
              className="codecademy-photo-modal"
              alt="Udemy logo"
            />
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                wordSpacing: "-6px",
              }}
            >
              Course:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              Figma UI UX Design Essentials
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "35auto",
              }}
            >
              Course Duration:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              11.5 Hours
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
              }}
            >
              Crash course on Figma mechanics and principles, UX Design
              Principles such as Personas, UI Design tools and resources.{" "}
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1.3rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                fontWeight: "bold",
              }}
            >
              65% Completed
            </Typography>
            <LinearProgress
              variant="determinate"
              thickness={4}
              value={65}
              sx={{
                width: "85%",
                mt: 1,
                ml: 5,
                mb: 1,
                borderRadius: "8px",
                p: "4px",
                display: "flex",
              }}
            />
          </Box>
        </Box>
      </Modal>

      {/*Google Modal*/}

      <Modal
        open={openGoogle}
        onClose={handleCloseG}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 600, border: null, display: "flex" }}>
          <Box sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
            <img
              src={GoogleImage}
              className="google-photo-modal"
              alt="Google logo"
            />
            <Typography
              sx={{
                color: "#28282B",
                pt: 4,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                wordSpacing: "-6px",
              }}
            >
              Professional Certificate:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              Google UX Design
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "35auto",
              }}
            >
              Course Duration:
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
                width: "auto",
              }}
            >
              6 Months
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
              }}
            >
              Google's UX design course spanning foundations, including user
              empathy, building wireframes and prototypes, and conducting
              research to test designs. As well as UX concepts, like
              user-centered design, accessibility, and equity-focused design.
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 2,
                fontSize: "1.3rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "auto",
                fontWeight: "bold",
              }}
            >
              42% Completed
            </Typography>
            <LinearProgress
              variant="determinate"
              thickness={4}
              value={42}
              sx={{
                width: "85%",
                mt: 1,
                ml: 5,
                mb: 1,
                borderRadius: "8px",
                p: "4px",
              }}
            />
          </Box>
        </Box>
      </Modal>

      <Paper
        sx={{
          width: "100vw",
          backgroundColor: "rgb(83, 148, 189)",
          height: "auto",
          borderRadius: "100px 100px 0px 0px",
          pb: 40,
          position: "relative",
          zIndex: 12,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial Menu"
          direction="down"
          sx={{
            position: "absolute",
            zIndex: 12,
            right: 120,
            top: 165,
            width: "2rem",
          }}
          icon={
            <ChangeHistoryIcon
              className="pulse-button"
              openIcon={<ChangeHistoryIcon />}
              sx={{ color: "rgba(245, 245, 245, 0.9)", fontSize: "3rem" }}
            />
          }
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
        <Box id="experience" sx={{ position: "absolute", top: 88 }}>
          {" "}
        </Box>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Typography
              className="flip-horizontal-fwd"
              sx={{
                color: "rgba(245, 245, 245, 0.9)",
                fontSize: "7rem",
                fontFamily: "Lekton",
                textAlign: "right",
                paddingRight: 22,
              }}
            >
              experience
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Parallax
              onProgressChange={(progress) => setProgress(progress)}
              translateX={["-110%", "25%"]}
              easing={"easeOutCubic"}
            >
              <Paper
                elevation={6}
                sx={{
                  width: "75vw",
                  backgroundColor: "#f7f6f6",
                  height: "auto",
                  borderRadius: "100px 100px",
                  paddingX: 4,
                  paddingY: 4,
                  mt: -4,
                }}
              >
                <Box>
                  <Carousel
                    enableKeyboardControls={true}
                    renderCenterLeftControls={null}
                    renderCenterRightControls={null}
                    animation={"zoom"}
                    zoomScale={"0.35"}
                    speed={777}
                  >
                    {/* New City as a School Experience Card */}
                    <Paper
                      elevation={6}
                      className="we_container"
                      sx={{
                        position: "relative",
                        zIndex: 8,
                        width: "50vw",
                        height: "auto",
                        backgroundColor: "black",
                        borderRadius: "50px 50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginX: "auto",
                        ml: "6.5vw",
                        mt: 2,
                        mb: 2,
                        p: 6,
                      }}
                    >
                      <Box
                        className="overlayWorkExp"
                        sx={{
                          position: "absolute",
                          zIndex: 4,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            paddingY: "auto",
                            position: "absolute",
                            m: 2,
                          }}
                        >
                          <img
                            src={cityAsASchoolLogo}
                            alt="City as a School Logo"
                          />
                        </Box>
                      </Box>

                      <Box className="info_container">
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            fontWeight: "bold",
                            mb: 2,
                          }}
                        >
                          City as a School
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Position:
                          </span>{" "}
                          Founding Engineer (Applied AI Architecture)
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Duration:
                          </span>{" "}
                          July '23 - Present
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            ...scrollableTypographyStyle,
                          }}
                        >
                          As a Founding Engineer at City as a School, I have
                          fully owned the development of{" "}
                          <span style={{ fontWeight: "bold" }}>Ark</span>, an
                          AI-powered Tutor and Career Guidance Agent. My role
                          involves leveraging cutting-edge AI
                          technologies—including nearly every available
                          production-ready API endpoint—to create innovative
                          educational learning pathways tailored to individual
                          career objectives, AI-powered job search tools, and
                          the ability to build portfolios through AI-guided
                          projects. In this application, we aimed to create an
                          agentic system capable of referencing multiple AI API
                          endpoints such as{" "}
                          <span style={{ fontWeight: "bold" }}>Perplexity</span>
                          , <span style={{ fontWeight: "bold" }}>Groq</span>,
                          and <span style={{ fontWeight: "bold" }}>OpenAI</span>
                          . The system can cue components, navigate browsers,
                          and interact with{" "}
                          <span style={{ fontWeight: "bold" }}>
                            Notion databases
                          </span>{" "}
                          via AI function-calling. Other integrated technologies
                          include{" "}
                          <span style={{ fontWeight: "bold" }}>
                            Text-to-Speech (TTS)
                          </span>
                          ,{" "}
                          <span style={{ fontWeight: "bold" }}>
                            Speech-to-Text (STT)
                          </span>
                          , and the use of{" "}
                          <span style={{ fontWeight: "bold" }}>
                            Vector Databases
                          </span>
                          . My contributions have been instrumental in advancing
                          City as a School's mission to revolutionize education
                          through AI, providing personalized learning
                          experiences and career guidance at scale.
                        </Typography>
                      </Box>
                    </Paper>

                    {/* New Chipp Experience Card */}
                    <Paper
                      elevation={6}
                      className="we_container"
                      sx={{
                        position: "relative",
                        zIndex: 8,
                        width: "50vw",
                        height: "auto",
                        backgroundColor: "black",
                        borderRadius: "50px 50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginX: "auto",
                        ml: "6.5vw",
                        mt: 2,
                        mb: 2,
                        p: 6,
                      }}
                    >
                      <Box
                        className="overlayWorkExp"
                        sx={{
                          position: "absolute",
                          zIndex: 4,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            paddingY: "auto",
                            position: "absolute",
                            m: 2,
                          }}
                        >
                          <img src={chippLogo} alt="Chipp Logo" />
                        </Box>
                      </Box>

                      <Box className="info_container">
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            fontWeight: "bold",
                            mb: 2,
                          }}
                        >
                          Chipp
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Position:
                          </span>{" "}
                          Co-Founder, Founding Engineer, Chief AI Officer
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Duration:
                          </span>{" "}
                          July '23 - March '24
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            ...scrollableTypographyStyle,
                          }}
                        >
                          As Co-Founder, Founding Engineer, and Chief AI Officer
                          at Chipp, I played a pivotal role in shaping our
                          startup's technological direction as it evolved. I
                          spearheaded the development of our initial product, an
                          AI chat interface called{" "}
                          <span style={{ fontWeight: "bold" }}>
                            BenchmarkAI
                          </span>
                          . This platform empowered users to query a
                          comprehensive
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            BigQuery
                          </span>{" "}
                          database containing every available U.S. non-profit
                          tax return, I constructed one of the earliest
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            SQL agents
                          </span>
                          . I managed the end-to-end data pipeline by
                          extracting, cleaning, and converting raw tax return
                          data from the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            IRS database
                          </span>
                          , and efficiently storing it in BigQuery. The AI agent
                          I developed could query this data, vectorize the
                          results for rapid retrieval, and display them in an
                          interactive table. It also enhanced user experience by
                          answering questions through the generated{" "}
                          <span style={{ fontWeight: "bold" }}>
                            vector database
                          </span>
                          , utilizing advanced{" "}
                          <span style={{ fontWeight: "bold" }}>
                            natural language processing
                          </span>
                          . In subsequent product iterations, I contributed to
                          the design of the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            payments platform UI dashboards
                          </span>
                          , improving user engagement and transaction
                          transparency. Ultimately, I architected the MVP of the
                          agent building system for the current platform, which
                          is foundational to Chipp's ability to build and deploy
                          <span style={{ fontWeight: "bold" }}>
                            AI agents at scale
                          </span>
                          . My contributions have been instrumental in
                          positioning Chipp at the forefront of scalable{" "}
                          <span style={{ fontStyle: "italic" }}>and</span>{" "}
                          deployable AI solutions.
                        </Typography>
                      </Box>
                    </Paper>
                    <Paper
                      elevation={6}
                      className="we_container"
                      sx={{
                        position: "relative",
                        zIndex: 8,
                        width: "50vw",
                        height: "auto",
                        backgroundColor: "black",
                        borderRadius: "50px 50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginX: "auto",
                        ml: "6.5vw",
                        mt: 2,
                        mb: 2,
                        p: 6,
                      }}
                    >
                      <Box
                        className="overlayWorkExp"
                        sx={{
                          position: "absolute",
                          zIndex: 4,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            paddingY: "auto",
                            position: "absolute",
                            m: 2,
                          }}
                        >
                          <img src={gcImage} alt="Ghost Creativ logo" />
                        </Box>
                      </Box>

                      <Box className="info_container">
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            fontWeight: "bold",
                            mb: 2,
                          }}
                        >
                          Ghost Creativ
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Position:
                          </span>{" "}
                          Software Engineer
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Contract Duration:
                          </span>{" "}
                          Dec '22 - May '23
                        </Typography>

                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            ...scrollableTypographyStyle,
                          }}
                        >
                          I led the implementation and redesign of frontend
                          components for various enterprise web applications,
                          enhancing performance and user experience. I built
                          numerous business dashboards to accompany
                          customer-facing mobile applications, improving data
                          visualization and engagement. Technologies I used
                          daily included{" "}
                          <span style={{ fontWeight: "bold" }}>JavaScript</span>
                          , <span style={{ fontWeight: "bold" }}>React.js</span>
                          , <span style={{ fontWeight: "bold" }}>Firebase</span>{" "}
                          for database management and deployments,{" "}
                          <span style={{ fontWeight: "bold" }}>
                            Material UI
                          </span>{" "}
                          for styling,{" "}
                          <span style={{ fontWeight: "bold" }}>Git</span> for
                          version control, and{" "}
                          <span style={{ fontWeight: "bold" }}>GitHub</span> for
                          repository management.
                        </Typography>
                      </Box>
                    </Paper>
                    <Paper
                      elevation={6}
                      className="we_container"
                      sx={{
                        position: "relative",
                        zIndex: 8,
                        width: "50vw",
                        height: "auto",
                        backgroundColor: "black",
                        borderRadius: "50px 50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginX: "auto",
                        ml: "6.5vw",
                        mt: 2,
                        mb: 2,
                        p: 6,
                      }}
                    >
                      <Box
                        className="overlayWorkExp"
                        sx={{
                          position: "absolute",
                          zIndex: 4,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            paddingY: "auto",
                            position: "absolute",
                            m: 2,
                          }}
                        >
                          <img
                            src={privateLogo}
                            className="privateLogo"
                            alt="Private logo"
                            style={{ fontStyle: "italic" }}
                          />
                        </Box>
                      </Box>

                      <Box className="info_container">
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            fontWeight: "bold",
                            mb: 2,
                          }}
                        >
                          Private Startup
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Position:
                          </span>{" "}
                          Software Engineer
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Contract Duration:
                          </span>{" "}
                          Oct '22 - Mar '23
                        </Typography>

                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            ...scrollableTypographyStyle,
                          }}
                        >
                          I assisted a local Senior Software Engineer maintain a
                          side-project in the form of part-time
                          contracting/sub-contracting. Responsible for designing
                          and building Next.js frontend templates for Content
                          Management Systems (CMS's).{" "}
                        </Typography>
                      </Box>
                    </Paper>
                    <Paper
                      elevation={6}
                      className="we_container"
                      sx={{
                        position: "relative",
                        zIndex: 8,
                        width: "50vw",
                        height: "auto",
                        backgroundColor: "black",
                        borderRadius: "50px 50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginX: "auto",
                        ml: "6.5vw",
                        mt: 2,
                        mb: 2,
                        p: 6,
                      }}
                    >
                      <Box
                        className="overlayWorkExp"
                        sx={{
                          position: "absolute",
                          zIndex: 4,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            paddingY: "auto",
                            position: "absolute",
                            m: 2,
                          }}
                        >
                          <img
                            src={cottageImage}
                            className="cottageLogo"
                            alt="Cottage Software logo"
                          />
                        </Box>
                      </Box>

                      <Box className="info_container">
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            fontWeight: "bold",
                            mb: 2,
                          }}
                        >
                          Cottage Software
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Position:
                          </span>{" "}
                          Software Engineer
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            mb: 2,
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            Contract Duration:
                          </span>{" "}
                          Aug - Oct '22
                        </Typography>

                        <Typography
                          sx={{
                            color: "rgba(245, 245, 245, 0.9)",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            fontFamily: "Lekton",
                            ...scrollableTypographyStyle,
                          }}
                        >
                          During my contract with Cottage, I was responsible for
                          designing, developing, and/or debugging various
                          Next.js/React components for a few different clients.
                          Our stack included Next.js, TypeScript, Github
                          (version-control) Vercel (deployment), Material UI
                          (styling), and Airtable (back-end). During this
                          contract I regularly exceeded expectations and always
                          attempted to over-deliver when appropriate and
                          applicable.
                        </Typography>
                        <Stack
                          direction="row"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <WebResponsiveExample />
                          <MobileResponsiveExample />
                        </Stack>
                      </Box>
                    </Paper>
                  </Carousel>
                </Box>
              </Paper>
            </Parallax>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CertificationComponent;
