import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typewriter from "typewriter-effect";
import Laptop from "././assets/laptop-png-6759.png";
import CertificationComponent from "../introduction/components/certification";
import Github from "./components/github";
import Skills from "./components/skills";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import HandymanIcon from "@mui/icons-material/Handyman";
import GitHubIcon from "@mui/icons-material/GitHub";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";

const actions = [
  {
    icon: (
      <a href="#about">
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "about",
  },
  {
    icon: (
      <a href="#skills">
        <HandymanIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "skills",
  },
  {
    icon: (
      <a href="#github">
        <GitHubIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "github",
  },
  {
    icon: (
      <a href="#experience">
        <SchoolIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "experience",
  },
  {
    icon: (
      <a href="#portfolio">
        <WorkIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <a href="#testimonials">
        <RecommendIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <a href="#contact">
        <MailIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "contact",
  },
];

export default function Introduction() {
  const [progress, setProgress] = useState(0);
  const [booleanToggle, setBooleanToggle] = useState(false);

  const handleClick = () => {};

  return (
    <Container>
      <Paper
        sx={{
          width: "100vw",
          backgroundColor: "#f7f6f6",
          height: "auto",
          borderRadius: "100px 100px 0px 0px",
          pb: 40,
          position: "relative",
          zIndex: 12,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial Menu"
          direction="down"
          sx={{
            position: "absolute",
            zIndex: 12,
            left: 120,
            top: 165,
            width: "2rem",
          }}
          icon={
            <ChangeHistoryIcon
              className="pulse-button"
              openIcon={<ChangeHistoryIcon />}
              sx={{ color: "#28282B", fontSize: "3rem" }}
            />
          }
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Typography
              id="about"
              className="flip-horizontal-fwd"
              sx={{
                color: "#28282B",
                fontSize: "7rem",
                fontFamily: "Lekton",
                textAlign: "left",
                paddingLeft: 22,
              }}
            >
              about
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 28, position: "relative" }}>
            <Box>
              <img
                altt="laptop"
                src={Laptop}
                style={{
                  display: "flex",
                  width: "65%",
                  right: 24,
                  position: "absolute",
                  zIndex: 2,
                  transform: "translateY(-85px)",
                }}
              />
              <Box
                sx={{
                  width: "65%",
                  position: "absolute",
                  right: -155,
                  top: 140,
                }}
              >
                {booleanToggle ? (
                  <Typewriter
                    options={{
                      delay: 25,
                      deleteSpeed: -55,
                    }}
                    id="typewriter"
                    onInit={(typewriter) => {
                      typewriter
                        .pauseFor(500)
                        .typeString(
                          '<span style=" font-family: Lekton; line-spacing: 20px; font-size: 1.8vw;">Hi! I\'m Andre</span><br/><br/><br/>'
                        )
                        .pauseFor(500)
                        .pauseFor(500)
                        .typeString(
                          "<span style=\" font-family: Lekton; line-spacing: 20px; font-size: 1.6vw;\">I'm an Applied AI Full Stack Software Engineer. <br/> Building beautiful, intelligent applications<br/> that solve real-world problems is more than<br/> a job to me. It's my passion. I also<br/>  enjoy a fresh coffee, bouldering, skating,<br/>  and walks on the beach. <br/><br/>I look forward to connecting with you!</span>"
                        )
                        .pauseFor(1000)
                        .start();
                    }}
                  />
                ) : (
                  <Box className="keys">
                    <div
                      className="start-key"
                      onClick={() => {
                        setBooleanToggle(true);
                      }}
                      data-key="65"
                      class="key__button"
                    >
                      Start
                    </div>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/*} <Grid item xs={8}>
          <Parallax 
          onProgressChange={(progress) => setProgress(progress)} 
          translateX={[
            '100%',
            '-50%'
            ]}
            easing={"easeOutCubic"}>
          <Paper
          elevation={6}
            sx={{ 
              width: "1000px",
              backgroundColor: "rgba(245, 245, 245, 0.1)",
              height: "400px",
              borderRadius: "100px 100px",
              display: "flex",
              marginLeft: 64,
              
              }}>
          <Box sx={{
              p: "2rem 0rem",
              m: "1rem", 
              }}>
          <Typography 
            sx={{ 
              color: "rgba(245, 245, 245, 0.9)",
              width: "590px",
              fontSize: '3.5rem',
              fontFamily: "Lekton",
              textAlign: "right",
              pb: 1,
              pt: 2,
              ml: 2,
                        }}>
               Hi, I'm Andre. 
              </Typography>
            <Typography 
            sx={{ 
              color: "rgba(245, 245, 245, 0.9)",
              width: "590px",
              fontSize: '1.6rem',
              fontFamily: "Lekton",
              textAlign: "right",
                        }}>
               I'm <a>passionate</a> about     
            </Typography>
            <Typography 
            sx={{ 
              color: "rgba(245, 245, 245, 0.9)",
              width: "590px",
              fontSize: '1.6rem',
              fontFamily: "Lekton",
              textAlign: "right",
                        }}>
               <a>front-end web development</a>    
            </Typography>
            <Typography 
            sx={{ 
              color: "rgba(245, 245, 245, 0.9)", 
              width: "590px",
              fontSize: '1.6rem',
              fontFamily: "Lekton",
              textAlign: "right",
              pl: 2,
                        }}>
               and eloquently-responsive <a>design</a>.   
            </Typography>
           
              </Box>
            <Avatar src={img} sx={{ width: 260, height: 260, marginY: "4rem", }}/>
            
            </Paper>
            </Parallax>
          </Grid>*/}
          <Grid
            item
            xs={12}
            sx={{
              transform: "translateY(28rem)",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Skills />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              transform: "translateY(22.2rem)",
              position: "relative",
              zIndex: 2,
              mb: 1,
            }}
          >
            <Github />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              transform: "translateY(16rem)",
              position: "relative",
              zIndex: 2,
            }}
          >
            <CertificationComponent
              progress={progress}
              setProgress={setProgress}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
