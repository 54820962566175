import React from "react";
import googleImage from "../../../../portfolio/images/googlehostingLogo.png";
import vercelImage from "../../../../portfolio/images/vercelLogo.png";
import azurehostingImage from "../../../../portfolio/images/azurehostingLogo.png";
import replitImage from "../../../../portfolio/images/replitLogo.png";
import SkillsComponent from "../../skills-component";

const CloudHostingCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent
              image={replitImage}
              title={"Replit"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={null} title={""} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={azurehostingImage}
              title={"Microsoft Azure"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={null} title={""} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={googleImage}
              title={"Google Cloud"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={null} title={""} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={vercelImage}
              title={"Vercel"}
              extraClass={"vercellogo"}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={null} title={""} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={null} title={""} extraClass={null} />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default CloudHostingCarosel;
