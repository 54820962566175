import React from "react";
import geminiImage from "../../../../portfolio/images/geminiLogo.png";
import selfhostedImage from "../../../../portfolio/images/selfhostLogo.webp";
import vertexaiImage from "../../../../portfolio/images/vertexaiLogo.png";
import groqImage from "../../../../portfolio/images/groqLogo.png";
import perplexityImage from "../../../../portfolio/images/perplexityLogo.png";
import anthropicImage from "../../../../portfolio/images/claudeLogo.webp";
import azureImage from "../../../../portfolio/images/azureaiLogo.png";
import openaiImage from "../../../../portfolio/images/openai-image.png";
import huggingfaceImage from "../../../../portfolio/images/huggingface-logo.png";
import SkillsComponent from "../../skills-component";

const LLMsCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent
              image={openaiImage}
              title={"OpenAI"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={anthropicImage}
              title={"Anthropic"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={groqImage}
              title={"Groq"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={perplexityImage}
              title={"Perplexity"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={azureImage}
              title={"Microsoft Azure AI"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={vertexaiImage}
              title={"Google Vertex AI"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={geminiImage}
              title={"Google Gemini"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={selfhostedImage}
              title={"Self-hosted"}
              extraClass={"vercellogo"}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={huggingfaceImage}
              title={"HuggingFace Transformers"}
              extraClass={null}
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default LLMsCarosel;
