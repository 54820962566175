import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { Parallax } from "react-scroll-parallax";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import HandymanIcon from "@mui/icons-material/Handyman";
import GitHubIcon from "@mui/icons-material/GitHub";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";
import nextjsImage from "./images/nextjsLogo.png";
import reactImage from "./images/reactLogo.png";
import javascriptImage from "./images/javascriptLogo.png";
import htmlImage from "./images/htmlLogo.png";
import cssImage from "./images/cssLogo.webp";
import muiImage from "./images/muiLogo.png";
import airtableImage from "./images/airtableLogo.png";
import vercelImage from "./images/vercelLogo.png";
import codeSnippetImage from "./images/codeSnippetsImage.png";
import Carousel from "nuka-carousel";
import arkImage from "./images/ark_logo.jpg";
import ark_example_video from "./videos/ark_example_video.gif";
import pythonImage from "./images/python-logo.png";
import typescriptImage from "./images/typescriptLogo.png";
import OpenAIImage from "./images/openai-image.png";
import GroqImage from "./images/groqLogo.png";
import supabaseImage from "./images/supabaseLogo.png";
import tailwindImage from "./images/tailwindImage.png";
import perplexityImage from "./images/perplexityLogo.png";

const actions = [
  {
    icon: (
      <a href="#about">
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "about",
  },
  {
    icon: (
      <a href="#skills">
        <HandymanIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "skills",
  },
  {
    icon: (
      <a href="#github">
        <GitHubIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "github",
  },
  {
    icon: (
      <a href="#experience">
        <SchoolIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "experience",
  },
  {
    icon: (
      <a href="#portfolio">
        <WorkIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <a href="#testimonials">
        <RecommendIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <a href="#contact">
        <MailIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "contact",
  },
];

const scrollableTypographyStyle = {
  maxHeight: "50vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "10px",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.7)",
    },
  },
};

export default function PortfolioExample() {
  const [progress, setProgress] = useState(0);

  return (
    <Container>
      <Paper
        maxWidth={true}
        sx={{
          positon: "relative",
          zIndex: 3,
          pb: 44,
          width: "100vw",
          backgroundColor: "#f7f6f6" /*4ABDAC*/,
          height: "auto",
          borderRadius: "100px",
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial Menu"
          direction="down"
          sx={{
            position: "absolute",
            zIndex: 12,
            left: 136,
            top: 160,
            width: "2rem",
          }}
          icon={
            <ChangeHistoryIcon
              className="pulse-button"
              openIcon={<ChangeHistoryIcon />}
              sx={{ color: "#28282B", fontSize: "3rem" }}
            />
          }
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
        <Box id="portfolio" sx={{ position: "absolute", top: 88 }}>
          {" "}
        </Box>
        <Typography
          className="flip-horizontal-fwd"
          sx={{
            color: "#28282B",
            pt: 10,
            fontSize: "7rem",
            fontFamily: "Lekton",
            textAlign: "left",
            paddingLeft: 22,
          }}
        >
          portfolio
        </Typography>

        <Parallax
          onProgressChange={(progress) => setProgress(progress)}
          translateX={["-70%", "30%"]}
          easing={"easeOutCubic"}
        ></Parallax>
        <Parallax
          onProgressChange={(progress) => setProgress(progress)}
          translateX={["100%", "7%"]}
          easing={"easeOutCubic"}
        >
          <Paper
            elevation={6}
            sx={{
              width: "60vw",
              backgroundColor: "#f7f6f6",
              height: "auto",
              borderRadius: "100px 100px",
              paddingX: 4,
              paddingY: 4,
              mt: 8,
              display: "flex", // Added
              justifyContent: "center", // Added
              alignItems: "center", // Added
            }}
          >
            <Box sx={{ width: "65vw", marginX: "auto" }}>
              <Carousel
                enableKeyboardControls={true}
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
                animation={"zoom"}
                zoomScale={"0.35"}
                speed={777}
              >
                <Box sx={{ width: "65vw", marginX: "auto" }}>
                  <Paper
                    elevation={6}
                    className="pp_container"
                    sx={{
                      position: "relative",
                      zIndex: 8,
                      width: "40vw",
                      height: "auto",
                      backgroundColor: "black",
                      borderRadius: "50px 50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginX: "auto",
                      mt: 3,
                      mb: 2,
                      p: 6,
                    }}
                  >
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        zIndex: 4,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                        borderRadius: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage: `url(${arkImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></Box>

                    <Box className="info_container">
                      <img
                        alt="ark_example_video"
                        src={ark_example_video}
                        className="csImage"
                        onClick={() => {
                          window.open(
                            "https://ark.cityasaschool.com/",
                            "_blank"
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />

                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginX: "2vw",
                          marginRight: "2vw",
                          marginTop: ".5vw",
                        }}
                        direction="row"
                      >
                        <img
                          alt="pythonImage"
                          src={pythonImage}
                          className="techStack nextjslogo"
                        />
                        <img
                          alt="typescriptImage"
                          src={typescriptImage}
                          className="techStack"
                        />
                        <img
                          alt="OpenAIImage"
                          src={OpenAIImage}
                          className="techStack"
                        />
                        <img
                          alt="GroqImage"
                          src={GroqImage}
                          className="techStack"
                        />
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginTop: "1vw",
                          marginX: "2vw",
                          marginLeft: "2vw",
                        }}
                        direction="row"
                      >
                        <img
                          alt="perplexityImage"
                          src={perplexityImage}
                          className="techStack vercellogo"
                        />
                        <img
                          alt="nextjsImage"
                          src={nextjsImage}
                          className="techStack nextjslogo"
                        />
                        <img
                          alt="supabaseImage"
                          src={supabaseImage}
                          className="techStack airtablelogo"
                        />
                        <img
                          alt="tailwindImage"
                          src={tailwindImage}
                          className="techStack"
                        />
                      </Stack>
                      <Typography
                        sx={{
                          position: "relative",
                          zIndex: 2,
                          color: "rgba(245, 245, 245, 0.9)",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          fontFamily: "Lekton",
                          margin: "1rem",
                          width: "90%",
                          marginX: "auto",
                          mt: 2,
                          ...scrollableTypographyStyle,
                        }}
                      >
                        Ark is an advanced AI-powered Tutor and Career Guidance
                        Agent designed to revolutionize personalized learning.
                        Leveraging cutting-edge AI technologies, Ark provides
                        tailored educational pathways, real-time tutoring, and
                        career guidance to students. It adapts to individual
                        learning styles, offers interactive problem-solving
                        sessions, and helps students explore potential career
                        paths based on their interests and strengths. Ark
                        represents a significant step forward in using AI to
                        enhance and democratize education.
                      </Typography>
                      <Typography
                        sx={{
                          position: "relative",
                          zIndex: 2,
                          color: "rgba(245, 245, 245, 0.9)",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          fontFamily: "Lekton",
                          width: "90%",
                          marginX: "auto",
                        }}
                      >
                        <a
                          className="links"
                          href="https://ark.cityasaschool.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Website
                        </a>
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
                <Box>
                  <Paper
                    elevation={6}
                    className="pp_container"
                    sx={{
                      position: "relative",
                      zIndex: 8,
                      width: "40vw",
                      height: "auto",
                      backgroundColor: "black",
                      borderRadius: "50px 50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginX: "auto",
                      mt: 3,
                      mb: 2,
                      p: 6,
                    }}
                  >
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        zIndex: 4,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                        borderRadius: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(245, 245, 245, 0.9)",
                          fontSize: "18rem",
                          textAlign: "center",
                          paddingY: "auto",
                          fontFamily: "Lekton",
                          position: "absolute",
                          m: 2,
                        }}
                      >
                        cS
                      </Typography>
                    </Box>

                    <Box className="info_container">
                      {/* <Typography sx={{textAlign: "center", fontFamily: "Lekton", fontSize: "3rem", marginTop: "1rem", color: "white",}}>
                    codeSnippets
                  </Typography> */}

                      <img
                        alt="codeSnippetImage"
                        src={codeSnippetImage}
                        className="csImage"
                      />

                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginX: "2vw",
                          marginRight: "2vw",
                          marginTop: ".5vw",
                        }}
                        direction="row"
                      >
                        <img
                          alt="nextjsImage"
                          src={nextjsImage}
                          className="techStack nextjslogo"
                        />
                        <img
                          alt="reactImage"
                          src={reactImage}
                          className="techStack"
                        />
                        <img
                          alt="javascriptImage"
                          src={javascriptImage}
                          className="techStack"
                        />
                        <img
                          alt="cssImage"
                          src={cssImage}
                          className="techStack"
                        />
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginTop: "1vw",
                          marginX: "2vw",
                          marginLeft: "3.5vw",
                        }}
                        direction="row"
                      >
                        <img
                          alt="htmlImage"
                          src={htmlImage}
                          className="techStack"
                        />
                        <img
                          alt="airtableImage"
                          src={airtableImage}
                          className="techStack airtablelogo"
                        />
                        <img
                          alt="muiImage"
                          src={muiImage}
                          className="techStack"
                        />
                        <img
                          alt="vercelImage"
                          src={vercelImage}
                          className="techStack vercellogo"
                        />
                      </Stack>
                      <Typography
                        sx={{
                          position: "relative",
                          zIndex: 2,
                          color: "rgba(245, 245, 245, 0.9)",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          fontFamily: "Lekton",
                          margin: "1rem",
                          width: "90%",
                          marginX: "auto",
                          ...scrollableTypographyStyle,
                        }}
                      >
                        An open-source code-snippet library tool that allows any
                        dev to clone, deploy, and enjoy!
                      </Typography>
                      <Typography
                        sx={{
                          position: "relative",
                          zIndex: 2,
                          color: "rgba(245, 245, 245, 0.9)",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          fontFamily: "Lekton",
                          width: "90%",
                          marginX: "auto",
                        }}
                      >
                        <a
                          className="links"
                          href="https://github.com/AndreLCanada/code-snippets"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Code
                        </a>
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Carousel>
            </Box>
          </Paper>
        </Parallax>
      </Paper>
    </Container>
  );
}
