import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { Parallax } from "react-scroll-parallax";
import RecommendationCardHunter from "./components/RecommendationCardHunter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import HandymanIcon from "@mui/icons-material/Handyman";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";

const actions = [
  {
    icon: (
      <a href="#about">
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "about",
  },
  {
    icon: (
      <a href="#skills">
        <HandymanIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "skills",
  },
  {
    icon: (
      <a href="#github">
        <GitHubIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "github",
  },
  {
    icon: (
      <a href="#experience">
        <SchoolIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "experience",
  },
  {
    icon: (
      <a href="#portfolio">
        <WorkIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <a href="#testimonials">
        <RecommendIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <a href="#contact">
        <MailIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "contact",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(204, 204, 204)",
    },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& fieldset": {
      borderColor: "rgb(38, 97, 246)",
    },
  },
  input: {
    color: "rgba(245, 245, 245, 0.9)",
    width: "20vw",
  },
});

const CustomLargeTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(204, 204, 204)",
    },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& fieldset": {
      borderColor: "rgb(38, 97, 246)",
    },
  },
  input: {
    color: "rgba(245, 245, 245, 0.9)",
    height: "80px",
    width: "",
  },
});

export default function Footer() {
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = React.useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const handleGithubClick = () => {
    window.open("https://github.com/AndreLCanada", "_blank").focus();
  };

  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/in/andre-l001/", "_blank").focus();
  };

  const handleEmailClick = () => {
    setShowTooltip(true);

    navigator.clipboard.writeText(`andrelacketech@gmail.com`);

    setTimeout(() => setShowTooltip(false), 2000);
  };

  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i80j1tf",
        "template_tyh8k5e",
        form.current,
        "yvIe6fjlqT7CqCmyQ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setOpen(false);
  };

  const handleOpen = () => {
    // open this link in a new tab: https://calendly.com/andrelacke/30min
    window.open("https://calendly.com/andrelacke/30min", "_blank").focus();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "90vh",
    bgcolor: "rgb(83, 148, 189)",
    border: "2px solid #000",
    overflow: "scroll",
  };

  return (
    <Container>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: "scroll," }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, border: null, display: "flex", marginX: "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgb(83, 148, 189)",
              width: "100vw",
            }}
          >
            <Typography
              className="text-pop-up-top"
              sx={{
                position: "absolute",
                top: 98,
                left: 88,
                fontSize: "2rem",
                fontFamily: "Major Mono Display",
                color: "rgba(245, 245, 245, 0.9)",
              }}
            >
              Andre
            </Typography>
            <Divider sx={{ pt: 18 }} />
            <Typography
              sx={{
                color: "rgba(245, 245, 245, 0.9)",
                pt: 4,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "45vw",
                marginX: "auto",
                fontWeight: "bold",
              }}
            >
              Have a great idea? Need help with a project? Own a company?
            </Typography>
            <Typography
              sx={{
                color: "rgba(245, 245, 245, 0.9)",
                pt: 2,
                fontSize: "1.6rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "55vw",
                marginX: "auto",
                fontWeight: "bold",
              }}
            >
              I'd love to hear about it!
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Stack
                direction="column"
                sx={{
                  width: "auto",
                  display: "flex",
                  justifyContent: "space-around",
                  mb: 12,
                }}
              >
                <form ref={form} onSubmit={sendEmail}>
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <CustomTextField
                      type="text"
                      name="from_name"
                      placeholder="Your name"
                      value={form.user_name}
                    />
                    <CustomTextField
                      type="text"
                      name="contact_email"
                      placeholder="Contact email"
                      value={form.contact_email}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <CustomTextField
                      type="text"
                      name="project_type"
                      placeholder="Project Type"
                      value={form.project_type}
                    />
                    <CustomTextField
                      type="text"
                      name="budget_range"
                      placeholder="Budget Range"
                      value={form.budget_range}
                    />
                  </Stack>
                  <CustomTextField
                    type="text"
                    name="message"
                    multiline
                    placeholder="Details"
                    value={form.message}
                    minRows={6}
                    sx={{
                      width: "47vw",
                      display: "flex",
                      alignSelf: "center",
                      mt: 2,
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      marginX: "auto",
                      py: 0,
                      px: 7,
                      mt: 3,
                      borderRadius: "100px",
                    }}
                    onClick={sendEmail}
                  >
                    <Typography
                      sx={{
                        color: "rgba(245, 245, 245, 0.9)",
                        fontSize: "1.4rem",
                        fontFamily: "Lekton",
                        textAlign: "center",
                      }}
                    >
                      Send
                    </Typography>
                  </Button>
                </form>
                <Divider color="black" sx={{ opacity: "0.1", mt: 3 }} />
              </Stack>
            </Box>
            <Button
              sx={{ position: "absolute", right: 48, top: 38 }}
              onClick={handleClose}
            >
              <CloseIcon
                sx={{
                  color: "rgba(245, 245, 245, 0.98)",
                  p: 2,
                  fontSize: "1.6rem",
                }}
              />
            </Button>
            {/*<Button sx={{ position: "absolute", right: 112, top: 38,}} onClick={handleClear}><ReplayIcon sx={{
                color: "rgba(245, 245, 245, 0.98)",
                p: 2,
                fontSize: '1.6rem',
              }} /></Button>*/}
          </Box>
        </Box>
      </Modal>
      <Paper
        sx={{
          width: "100vw",
          backgroundColor: "rgb(83, 148, 189)", //"#f7f6f6",
          height: "auto",
          borderRadius: "100px",
          marginBottom: -20,
          position: "relative",
          zIndex: 6,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial Menu"
          direction="down"
          sx={{
            position: "absolute",
            zIndex: 12,
            right: 120,
            top: 165,
            width: "2rem",
          }}
          icon={
            <ChangeHistoryIcon
              className="pulse-button"
              openIcon={<ChangeHistoryIcon />}
              sx={{ color: "#f7f6f6", fontSize: "3rem" }}
            />
          }
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>

        <Box id="testimonials" sx={{ position: "absolute", top: 88 }}>
          {" "}
        </Box>
        <Typography
          className="flip-horizontal-fwd"
          sx={{
            color: "#f7f6f6", //"#28282B",
            pt: 12,
            fontSize: "7rem",
            fontFamily: "Lekton",
            fontWeight: 400,
            textAlign: "right",
            paddingRight: 22,
          }}
        >
          testimonials
        </Typography>
        <Parallax
          onProgressChange={(progress) => setProgress(progress)}
          translateX={["-80%", "10%"]}
          easing={"easeOutCubic"}
        >
          <Paper
            elevation={6}
            sx={{
              width: "65vw",
              mt: 4,
              pb: 6,
              backgroundColor: "#f7f6f6",
              height: "auto",
              borderRadius: "100px",
              marginX: "auto",
              position: "relative",
              zIndex: 7,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              transform: "translateY(20px)",
            }}
          >
            <Stack direction="column">
              <Typography
                className="flip-horizontal-fwd"
                sx={{
                  color: "#28282B",
                  pt: 6,
                  fontSize: "2rem",
                  fontFamily: "Lekton",
                  textAlign: "center",
                }}
              >
                What people I have worked with have to say...
              </Typography>
              <RecommendationCardHunter />
            </Stack>
          </Paper>
        </Parallax>
        <Paper
          id="contact"
          elevation={6}
          sx={{
            width: "95vw",
            backgroundColor: "#f7f6f6",
            height: "170px",
            borderRadius: "20px",
            marginX: "auto",
            position: "relative",
            zIndex: 7,
            marginTop: "200px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            transform: "translateY(-20px)",
          }}
        >
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                ml: 6,
                fontSize: "2rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Have an idea?
            </Typography>

            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                width: "35vw",
              }}
            >
              Want to make it a reality? <br /> Let's schedule a time to chat!
            </Typography>
            <Typography
              onClick={handleOpen}
              className="brainstorm-button"
              sx={{
                paddingY: "1rem",
                paddingX: "1.4rem",
                color: "#28282B",
                fontSize: "1.2rem",
                textAlign: "center",
                borderRadius: "40px",
                backgroundColor: "#FFAD05",
                fontWeight: "bold",
                mr: 6,
                boxShadow: "3px 3px #28282Be8",
              }}
            >
              Let's Brainstorm!
            </Typography>
          </Stack>
        </Paper>
        <Paper
          sx={{
            width: "100vw",
            backgroundColor: "#5394BD",
            height: "600px",
            marginX: "auto",
            position: "static",
            zIndex: -1,
            marginTop: "-100px",
          }}
        >
          <Typography
            className="text-pop-up-top"
            sx={{
              justifySelf: "center",
              textAlign: "center",
              fontSize: "2rem",
              fontFamily: "Major Mono Display",
              color: "rgba(245, 245, 245, 0.9)",
              pt: 30,
            }}
          >
            Andre
          </Typography>
          <Typography
            sx={{
              color: "rgba(245, 245, 245, 0.9)",
              marginX: "auto",
              fontSize: "1.4rem",
              fontFamily: "Lekton",
              textAlign: "center",
              width: "35vw",
              transform: "translateY(-20px)",
            }}
          >
            A job is what you do,{<br />} passion is what you love.
          </Typography>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "40%",
              marginX: "auto",
              pt: 4,
            }}
          >
            <LinkedInIcon
              sx={{
                fontSize: "2rem",
                borderRadius: "50%",
                border: "2px solid #FCFF4B",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={handleLinkedinClick}
            />
            <Tooltip
              title={
                <Box sx={{ display: "relative" }}>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    Email copied to clipboard!
                  </Typography>
                </Box>
              }
              arrow
              open={showTooltip}
            >
              <EmailIcon
                sx={{
                  fontSize: "2rem",
                  borderRadius: "50%",
                  border: "2px solid #FCFF4B",
                  padding: 2,
                  cursor: "pointer",
                }}
                onClick={handleEmailClick}
              />
            </Tooltip>
            <GitHubIcon
              sx={{
                fontSize: "2rem",
                borderRadius: "50%",
                border: "2px solid #FCFF4B",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={handleGithubClick}
            />
          </Stack>
          <Typography
            sx={{
              color: "rgba(245, 245, 245, 0.9)",
              marginX: "auto",
              fontSize: "1.4rem",
              fontFamily: "Lekton",
              textAlign: "center",
              width: "35vw",
              transform: "translateY(-20px)",
              pt: 10,
            }}
          >
            Made with love by Andre. <br /> Built Oct 2022. Info Updated Sept
            2024.
          </Typography>
        </Paper>
      </Paper>
    </Container>
  );
}
