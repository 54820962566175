import React from "react";
import supabaseImage from "../../../../portfolio/images/supabaseLogo.png";
import firebaseImage from "../../../../portfolio/images/firebase-logo.png";
import colabImage from "../../../../portfolio/images/colab-logo.png";
import githubImage from "../../../../portfolio/images/githubLogo.png";
import gitpodImage from "../../../../portfolio/images/gitpodLogo.png";
import copilotImage from "../../../../portfolio/images/copilot-logo.png";
import vscodeImage from "../../../../portfolio/images/vscodeLogo.png";
import cursorImage from "../../../../portfolio/images/cursorLogo.webp";
import huggingfaceImage from "../../../../portfolio/images/huggingface-logo.png";
import SkillsComponent from "../../skills-component";

const ToolsCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent
              image={cursorImage}
              title={"Cursor"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={copilotImage}
              title={"Github Copilot"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={githubImage}
              title={"Github"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={gitpodImage}
              title={"Gitpod"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={vscodeImage}
              title={"VS Code"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={colabImage}
              title={"Google Colab"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={supabaseImage}
              title={"Supabase"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={firebaseImage}
              title={"Firebase"}
              extraClass={"vercellogo"}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={huggingfaceImage}
              title={"HuggingFace"}
              extraClass={null}
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default ToolsCarosel;
