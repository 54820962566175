import React from "react";
import nextjsImage from "../../../../portfolio/images/nextjsLogo.png";
import reactImage from "../../../../portfolio/images/reactLogo.png";
import muiImage from "../../../../portfolio/images/muiLogo.png";
import llamaLogo from "../../../../portfolio/images/llamaindex-logo.png";
import langchainImage from "../../../../portfolio/images/parrot-logo.png";
import storybookImage from "../../../../portfolio/images/storybookLogo.png";
import tailwindImage from "../../../../portfolio/images/tailwindImage.png";
import crewaiImage from "../../../../portfolio/images/crewaiLogo.png";
import reduxImage from "../../../../portfolio/images/reduxImage.png";
import SkillsComponent from "../../skills-component";

const FrameworksCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent
              image={nextjsImage}
              title={"Next.js"}
              extraClass={"nextjslogo"}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={reactImage}
              title={"React.js"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={reduxImage}
              title={"Redux.js"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={storybookImage}
              title={"Storybook"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={crewaiImage}
              title={"CrewAI"}
              extraClass={"crewai"}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={muiImage}
              title={"Material UI"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={tailwindImage}
              title={"Tailwind"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={langchainImage}
              title={"LangChain"}
              extraClass={null}
            />
          </figure>
          <figure class="shadow">
            <SkillsComponent
              image={llamaLogo}
              title={"LlamaIndex"}
              extraClass={null}
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default FrameworksCarosel;
